import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // client 页面是否需要清除缓存
    isKeepAlive: false,
    // 需要刷新的页面路径
    refreshPath: [],
    permission: window.atob(sessionStorage.getItem("sign")) || 2,
    // 模板问题列表
    tempQuestions: []
  },
  mutations: {
    updateTempQuestions(state, data) {
      state.tempQuestions = data
    },
    updateIsKeepAlive(state, data) {
      state.isKeepAlive = data
    },
    updateRefreshPath(state, data) {
      const index = state.refreshPath.findIndex(v => {
        return v == data
      })
      if (index == -1) {
        state.refreshPath.push(data)
      }
    },
    delRefreshPath(state, data) {
      const index = state.refreshPath.findIndex(v => {
        return v == data
      })
      state.refreshPath.splice(index, 1)
    },
    updatePermission(state, data) {
      state.permission = window.atob(data)
    }
  },
  actions: {
  },
  modules: {
  }
})
